import React, { useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Container, Drawer, Toolbar, Button, IconButton, Box, List, ListItemButton, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import logo from '../../static/M.svg';
import { StaticImage } from 'gatsby-plugin-image';

const Chrome = ({ children }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // const prefersDarkMode = false;
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    // background: {
                    //     paper: prefersDarkMode ? '#121212' : '#B2CB70',
                    //     default: prefersDarkMode ? '#121212' : '#DDB68680'
                    // },
                    // divider: prefersDarkMode ? '#121212' : '#A98C69',
                    primary: {
                        main: '#FFFFFF',
                    },
                    secondary: {
                        main: prefersDarkMode ? '#FFFFFF' : '#000000',
                    },
                    success: {
                        main: '#3F6334',
                    },
                    mode: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode],
    );
    const mobile = useMediaQuery('(max-width: 600px)');

    return (
        <ThemeProvider theme={theme}>
            <Helmet title="Michael Marchio" defer={false} htmlAttributes={{ lang: 'en' }}>
                <meta name="description" content="Michael Marchio personal website" />
                <meta name="image" content="https://www.gravatar.com/avatar/6333216c28036c5f55f7def47088708e" />
            </Helmet>
            <CssBaseline />
            <AppBar color={'primary'} position="sticky">
                <Toolbar>
                    {mobile ?
                        <>
                            <Link css={css`height: 48px; display: flex; align-items: center`} to="/">
                                <img height="36px" src={logo} alt="Marchio 'M' logo" />
                            </Link>
                            <Box css={css`display: flex; flex: 1 1 auto`} />
                            <IconButton
                                size='large'
                                onClick={handleClick}
                                onClose={handleClose}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Drawer
                                anchor="top"
                                open={open}
                                onClose={handleClose}
                            >
                                <List>
                                    <ListItemButton onClick={handleClose} component={Link} to="/">Home</ListItemButton>
                                    <ListItemButton onClick={handleClose} component={Link} to="/aboutMe">About Me</ListItemButton>
                                    {/* <ListItemButton onClick={handleClose} component={Link} to="/architecture">Architecture</ListItemButton> */}
                                    <ListItemButton onClick={handleClose} component={Link} to="/design">Design</ListItemButton>
                                    <ListItemButton onClick={handleClose} component={Link} to="/development">Development</ListItemButton>
                                    <ListItemButton onClick={handleClose} component={Link} to="/experience">Experience</ListItemButton>
                                </List>
                            </Drawer>
                        </>
                        :
                        <>
                            <Link css={css`height: 48px`} to="/">
                                <img height="48px" src={logo} alt="Marchio 'M' logo" />
                            </Link>
                            <Box css={css`display: flex; flex: 1 1 auto`} />
                            <Button color="secondary" component={Link} to="/aboutMe">About Me</Button>
                            {/* <Button color="secondary" component={Link} to="/architecture">Architecture</Button> */}
                            <Button color="secondary" component={Link} to="/design">Design</Button>
                            <Button color="secondary" component={Link} to="/development">Development</Button>
                            <Button color="secondary" component={Link} to="/experience">Experience</Button>
                        </>
                    }
                </Toolbar>
            </AppBar>
            <Container css={css`padding: 16px; display: flex; flex-direction: column; flex: 1 0 auto;`}>
                {children}
            </Container>
            <AppBar position='relative'>
                <Toolbar css={css`display: flex; justify-content: space-between`}>
                    <Typography>Marchio.dev</Typography>
                    <a 
                    href='https://www.linkedin.com/in/michaelmarchio/' 
                    target="_blank" 
                    rel="noreferrer">
                        <StaticImage height={36} src={'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/768px-LinkedIn_logo_initials.png'}/>
                    </a>
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    )
}

export default Chrome;