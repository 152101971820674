import "./src/styles/index.css";
import React, { Suspense, lazy } from 'react';
import { LinearProgress } from '@mui/material';

const Wrapper = lazy(() => 
    import("./src/layouts/Wrapper") 
);

export const wrapPageElement = ({ element }) => (
    <Suspense fallback ={<LinearProgress/>}>
        <Wrapper>{element}</Wrapper>
    </Suspense>
)



export const onInitialClientRender = () => {
    document.body.style.display = "block";
}