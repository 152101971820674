// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-content-about-me-mdx": () => import("./../../../src/content/aboutMe.mdx" /* webpackChunkName: "component---src-content-about-me-mdx" */),
  "component---src-content-architecture-md": () => import("./../../../src/content/architecture.md" /* webpackChunkName: "component---src-content-architecture-md" */),
  "component---src-content-design-md": () => import("./../../../src/content/design.md" /* webpackChunkName: "component---src-content-design-md" */),
  "component---src-content-experience-md": () => import("./../../../src/content/experience.md" /* webpackChunkName: "component---src-content-experience-md" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-development-index-js": () => import("./../../../src/pages/development/index.js" /* webpackChunkName: "component---src-pages-development-index-js" */),
  "component---src-pages-development-pokemon-js": () => import("./../../../src/pages/development/pokemon.js" /* webpackChunkName: "component---src-pages-development-pokemon-js" */),
  "component---src-pages-development-spacex-js": () => import("./../../../src/pages/development/spacex.js" /* webpackChunkName: "component---src-pages-development-spacex-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

